import { useUserStore } from 'store/user.store';
import { useInternalStore } from 'shared/store/internalStore.store';

export function useCatalog() {
  const userStore = useUserStore();
  const internalStore = useInternalStore();

  /** Показывает, доступен ли каталог для просмотра */
  const isCatalogAvailable = computed(() => userStore.isUserLoggedIn || internalStore.getIsInternalUser);

  return { isCatalogAvailable };
}
